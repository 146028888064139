@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lexend+Deca:wght@100..900&family=Outfit:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lexend+Deca:wght@100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #151515;
}

.navbar {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: max-content;
  padding-block: 10px;
  padding-inline: 40px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 0px 40px 40px;
  background: rgba(13, 13, 13, 0.7);
  position: fixed;
  z-index: 100;
  font-size: 20px;
}

.navbar-font {
  font-family: Outfit;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-items-list {
  display: flex;
  gap: 32px;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.nav-items-list>div {
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;
}

.nav-items-list>div:hover {
  color: #AA67FE;
}

.nav-items-list>div:hover>a>.spin-arrow>g>path {
  fill: #AA67FE;
  transition: all 0.3s ease;
}




.nav-button {
  display: flex;
  padding: 8px 24.5px 8px 25.5px;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  border: 2px solid #AA67FE;
  box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.12);
}

.nav-button:hover {
  background: #AA67FE;
  color: white !important;
  transition-property: all;
  transition-duration: 600ms;
  transition-timing-function: ease-out;
}

.hamburger {
  color: white !important;
}

.navMenu {
  width: 100%;
  height: 100vh;
  background: black;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  color: white;
  transform: translateX(-100%);
  touch-action: none;
}

.nav-open {
  transform: translateX(0);
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.nav-toggle-button {

  display: none !important;
}

.mobile-navbar {
  position: fixed;
  z-index: 10001;
  width: 100%;
  display: none !important;
  background: #000000a9;
  padding-block: 10px;
  padding-inline: 10px;
  box-sizing: border-box;
}

.mobile-navbar>div {
  flex-grow: 1;
}

.mobile-navbar>div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
}

.nav-item-container {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  font-size: 30px;
}

.nav-logo {}

.item {
  cursor: pointer;
}


.home-first {
  background-image: url(./illumination.png);
  background-position: center;
  background-color: #151515;
  background-size: cover;
  overflow: hidden;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: max-content;
  position: relative;
}

.content {
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1.7em;
}

.icons {
  position: absolute;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.btn-pill {
  display: flex;
  padding: 0.4375em;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  border: 0.0625em solid rgba(255, 255, 255, 0.05);
  background: linear-gradient(90deg,
      rgba(251, 137, 32, 0.14) 0%,
      rgba(255, 77, 1, 0.11) 100%);
  cursor: pointer;
  width: 16.9375em;
  height: 1em;
}

.btn-pill>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4375em;
}

.btn-pill>div>span {
  color: #ff9217;
  text-align: center;
  font-family: "Inter";
  font-size: 0.7375em;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  /* 135.593% */
}

.heading {
  font-family: Inter;
  font-size: 4.525em;
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(94deg, #e5f4ff 0%, #5dade5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subHeading {
  height: 4.125em;
  /* overflow: hidden; */
}

.subHeading>div>div {
  position: absolute;
}

.subHeading>div {
  display: flex;
  justify-content: center;
}

.subHeading>div>div>div {
  display: flex;
  /* padding: 0em 4.1875em 0em 4.3125em; */
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  /* gap: 2.4375em; */
  flex-shrink: 0;
  font-family: Inter;
  font-size: 3em;
  font-style: normal;
  font-weight: 700;
  /* line-height: 5.25em; */
  background: linear-gradient(94deg, #e5f4ff 0%, #5dade5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: flex-start;
  width: max-content;
}

.description {
  color: #f2f2f2;
  max-width: 26em;
  text-align: center;
  font-family: Inter;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75em;
  /* 156.425% */
}

.linkbtn {
  display: flex;
  padding: 0.6625em 2.6778em;
  justify-content: center;
  align-items: center;
  border-radius: 2.1875em;
  border: 0.0625em solid rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0em 0em 0em 0.1875em rgba(255, 255, 255, 0.2);
  color: #000;
  font-family: Inter;
  font-size: 0.9688em;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 0.0125em;
  cursor: pointer;
}

.morebtn {
  display: flex;
  height: 3.0063em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.9778em;
  cursor: pointer;
  position: relative;
  top: 2.8125em;
}

.morebtn>span {
  color: rgba(255, 255, 255, 0.4);
  font-family: Inter;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.0063em;
  /* 115.827% */
}

.rings {
  width: auto;
  height: 100vh;
  object-fit: contain;
  object-position: center;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icons {
  width: auto;
  height: 100vh;
  object-fit: contain;
  object-position: center;
  animation: rotate360 30s linear infinite;
}

.view-down {
  font-size: 1.1em;
  color: rgba(255, 255, 255, 0.4);
}

/* home-second css starts here */
.home-second {
  background-color: #1d1d1d;
  background-image: url(Glow-2.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  padding-block: 6rem 3rem;
  position: relative;
}

.title-white {
  color: #fff;
  /* text-align: center; */
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4.5rem;
  perspective: 1000px;
  /* 150% */
}

.subtitle {
  color: rgba(255, 255, 255, 0.6);

  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  /* 140% */
  max-width: 43rem;
  perspective: 1000px;
}

.gapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 22.375rem; */
  gap: 0.75rem;
}

.floating-shine {
  background-image: url("./hell.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 13rem;
  left: 0;
  top: 9.8125rem;
  position: sticky;
  display: block;
  width: 100%;
  z-index: 0;
}

/* home third section begins */
.home-third {
  width: 100%;
  height: max-content;
  background: url(81.png);
  background-position: right;
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  align-items: center;
  align-content: center;
}

.third-flex {
  display: flex;

  align-items: center;
  gap: 0.9375rem;
  justify-content: space-around;
  width: 100%;
  padding-block: 19vh;
}

.third-flex-text {
  flex-grow: 1;
}

.features {
  padding: 1.6875rem 1.5rem;
  border-radius: 1rem;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, 16.125rem);
  grid-template-rows: repeat(3, 2.9427rem);
  row-gap: 0.8919rem;
  column-gap: 1.0306rem;
  box-shadow: 0.25rem 0.25rem 2.5rem 0rem rgba(0, 0, 0, 0.05);
}

.features>div {
  border-radius: 0.375rem;
  background: #f0f8f8;
  color: #000;
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 0.7281rem;
  padding: 0rem 0.7281rem;
}

.features>div>svg {
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.third-flex-text {
  font-family: Outfit;
  font-size: 3.125rem;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1.875rem;
  max-width: 36.4375rem;
}

.third-flex-head-white {
  color: #fff;
  font-weight: 400;
  font-size: 3.125rem;
}

.third-flex-head-color {
  color: #aa67fe;
  font-weight: 700;
  font-size: 3.125rem;
}

.txt-left {
  text-align: left;
}

.outlined-btn {
  display: flex;
  padding: 0.5625rem 1.2813rem 0.5rem 1.2188rem;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 2.875rem;
  border: 0.125rem solid #9747ff;
  color: var(--var, #aa67fe);

  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 4.5rem;
  /* 300% */

  width: max-content;
  max-height: 3.125rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.outlined-btn:hover {
  color: white;
  background: #9747ff;
}

.outlined-btn:hover>svg>path {
  fill: white;
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.home-fourth {
  background-color: #000;
  padding: 5.9375rem 0rem;
}

.glass-outer {
  border-radius: 2.5rem;
  border-top: 0.0625rem solid rgba(218, 218, 218, 0.45);
  width: 50rem;
  height: 21.875rem;
  background: #232323;
  position: relative;
  padding: 0.625rem;
  box-sizing: border-box;
}

.glass-inner {
  border-radius: 2.1875rem;
  border-top: 0.0625rem solid rgba(104, 104, 104, 0.44);
  border-left: 0.0625rem solid rgba(104, 104, 104, 0.44);
  background: #292929;
  display: flex;
  width: 48.75rem;
  height: 20.625rem;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: nowrap;
}

.cool-image {
  width: 37.25rem;
  height: 15.6837rem;
  /* background-size: contain; */
  background-size: cover;
  background-repeat: no-repeat;
}

.horizontal-panes-fourth {
  display: flex;
  justify-content: space-between;
}

.horizontal-panes-fourth-reverse {
  flex-direction: row-reverse;
}

.glass-right {
  padding-right: 0rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  left: unset;
  right: -2.1875rem;
}

.glass-left {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  left: -2.1875rem;
  right: unset;
}

.panes-container {
  display: flex;
  flex-direction: column;
  gap: 12.8125rem;
}

.glass-inner::after {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  background: url(arrow.svg);
  position: absolute;
  top: 2.3125rem;
  right: 3.6875rem;
}

.glass-image {
  margin-left: auto;
  margin-right: auto;
  width: 30.625rem;
  height: 14.5rem;
  border-radius: 2.5rem;
  object-fit: cover;
  position: relative;
  top: 50%;
}

.glass-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.625rem 4.875rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 1.75rem;
}

.head {
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.purple-head {
  color: var(--var, #aa67fe);
}

.white-head {
  color: var(--var, #fff);
}

.glass-text-concept {
  color: #fff;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-fifth {
  padding-block: 17vh 14.5vh;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
}

.subtitle-fifth {
  padding-block: 1.875rem 3.25rem;
  max-width: 56.75rem;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 294px));
  grid-template-rows: repeat(4, 5.4375rem);
  grid-column-gap: 1.125rem;
  grid-row-gap: 2.4375rem;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  height: max-content;
}

.cards>div {
  border-radius: 0.625rem;
  color: #000;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cards>div {
  display: flex;
  gap: 1.125rem;
  align-items: center;
}

/* .cards > div > div {
  max-width: 11.875rem;
} */

.cards>div:nth-child(1)>div {
  max-width: 10.125rem;
}

.cards>div:nth-child(3)>div {
  max-width: 9.625rem;
}

.cards>div:nth-child(5)>div {
  max-width: 10rem;
}

.cards>div:nth-child(6)>div {
  max-width: 7.5rem;
}

.cards>div:nth-child(10)>div {
  max-width: 14.375rem;
}

.cards>div:nth-child(1) {
  background: #ecf3fe;
}

.cards>div:nth-child(2) {
  background: #fef8e6;
}

.cards>div:nth-child(3) {
  background: #fdf4f0;
}

.cards>div:nth-child(4) {
  background: #e7faf3;
}

.cards>div:nth-child(5) {
  background: #f1f1fb;
}

.cards>div:nth-child(6) {
  background: #ecf3fe;
}

.cards>div:nth-child(7) {
  background: #fef8e6;
}

.cards>div:nth-child(8) {
  background: #fdf4f0;
}

.cards>div:nth-child(9) {
  background: #e7faf3;
}

.cards>div:nth-child(10) {
  background: #e7faf3;
  grid-row: 4;
  grid-column: 2;
}

.home-sixth {
  background: #151515;
}

.subtitle-sixth {
  max-width: 48.125rem;
  padding-block: 2.5rem 1.5625rem;
}

.container-sixth {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.thumb-parent {
  background-color: transparent;
  gap: 3.0625rem;
  padding-inline: 10vw;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-template-rows: 28.75rem;
  padding-block: 5vh;
  position: relative;
  z-index: 1;
}

.image-container>img {
  border-radius: 1.5625rem;
  /* cursor: pointer; */
  width: 100%;
  /* max-width: 52.875rem;
  max-height: 28.75rem; */
  object-fit: cover;
}

.thumb-parent>div {
  /* cursor: pointer; */
}

a {
  text-decoration: none;
}

.footer {
  padding: 5.4375rem 7.9375rem;
  display: flex;
  justify-content: center;
}

.thumbGrid>div {
  cursor: pointer;
}

.article-container {
  height: 100vh;
  max-height: 770px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 81px;
  box-sizing: border-box;
  background: rgb(46, 46, 46);
  place-items: center;
}

.article-image {
  border-radius: 20px;
  box-shadow: rgba(255, 255, 255, 0.08) 61px 3px 148.2px 88px;
  width: 100%;
  max-width: 621px;
  height: auto;
}

.blackie {
  background-color: transparent;
}

.article-subcontainer {
  height: 100%;
}

.article-subcontainer-sub {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 621px;
}

@media screen and (max-width: 1280px) {
  .Footer {
    padding: 40px 20px !important;
  }

  .Maal {
    grid-template-columns: 1fr !important;
    gap: 50px;
  }

  .footerItemList {
    display: flex;
    flex-direction: column;
    align-content: center !important;
    align-items: center !important;
  }

  .footerItem div::before {
    content: "" !important;
  }

  .footerItem {
    text-align: inherit !important;
  }

  .footercloser {
    gap: 10px;
    padding-top: 3rem;
    text-align: left;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
  }

  .copyright {
    text-align: center;
  }

  .closerlist {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    font-family: "Lexend Deca";
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    flex-direction: column !important;
    align-items: center !important;
    gap: 10px;
  }

  .MaalThree>div {
    text-align: center !important;
  }

  .Footer h1 {
    font-size: 25px !important;
  }

  .thumb-parent {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 1.1875rem;
  }

  .image-container>img {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }

  .footer {
    padding: 3.4375rem 7.9375rem;
  }
}

.thumbGrid>div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  z-index: 9;
}

.thumbGrid>div>.image-container>img {
  width: 100%;
  height: auto;
  /* max-height: 11.4375rem; */
  object-fit: cover;
  border-radius: 1.5625rem;
}

.thumbGrid>div>a>.title-white {
  font-size: 1.5rem !important;
  line-height: 32px !important;
  padding-inline: 1.125rem;
  margin-top: 1.75rem;
}

.thumbGrid>div>a>.subtitle {
  font-size: 1rem;
  line-height: 23px !important;
  padding-inline: 1.125rem;
  margin-top: 9px;
}

.tp-reader {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.tools {
  padding-inline: 10vw;
  padding-block: 1.25rem 2.3125rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
}

.tools.back {
  visibility: hidden;
  z-index: 20;
}

.button-tools {
  display: flex;
  padding: 0.9375rem 1.25rem;
  background: transparent;
  color: #adadad;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  border: 0.0625rem solid #818181;
  cursor: pointer;
  width: fit-content;
}

.tools>div {
  display: flex;
  gap: 2.3125rem;
}

.search {
  display: flex;
  width: 26.4375rem;
  padding: 0.5625rem 1.625rem 0.5rem 1.625rem;
  align-items: center;
  gap: 1.3125rem;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.13);
  min-height: 2rem;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.image-overlay {
  content: "";
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  background: linear-gradient(105deg,
      rgba(124, 105, 150, 0.34) 3.63%,
      rgba(153, 93, 228, 0.27) 97.49%);
  border-radius: 1.5625rem;
}

.tools-header {
  padding-block: 8rem 0rem;
}

.icon-btn {
  display: flex;
  gap: 0.5625rem;
}

.blog-info>div {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.blog-info>div>div:first-child {
  color: #fff;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog-info>div>div:last-child {
  color: rgba(255, 255, 255, 0.6);

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  /* 125% */
}

.content-blog {
  color: #fff;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  /* 140% */
  padding-block: 3.75rem;
}

.content-blog-heading {
  color: #fff;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  /* padding-top: 1.875rem; */
}

.second-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 4.3125rem; */
  padding-block: 8.83vh;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}

.second-flex>div {
  flex-grow: 1;
}

.scroll-horizontal-section {
  padding-block: 10.7vh 10.7vh;
  /* gap: 9.09vh; */
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
}

.plate-horizontal {
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
}

.fading-plates {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.fading-plates>img {
  position: absolute;
  height: auto;
  width: 53.33vw;
}

.fading-plates>div {
  position: absolute;
  width: 53.33vw;
  height: 20rem;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.05);
  color: #898CA9;
  padding: 3.125rem 4.375rem;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  gap: 6.375rem;
  opacity: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.plate-icon {
  width: 8.4375rem;
  height: 8.4375rem;
}

.plate-text-title {
  color: #c253fe;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  /* 91.667% */
}

.plate-text-subtitle {
  color: var(--Neutral-600, #6f6c90);
  font-family: "DM Sans";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  /* 111.111% */
}

.plate-item {
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
}

.plate-left-icon {
  margin-left: 0.875rem;
  width: auto;
  height: 1.4375rem;
}

.about-hero-section {
  height: 100vh;
  max-height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 1.5625rem;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  background: url("./aboutframe.png");
  background-size: cover;
  background-position: 0px -55px;
  position: relative;
  /* background-attachment: fixed; */
  width: 100%;
  overflow-x: hidden;
}

.maxw-sub {
  max-width: 31.4375rem;
}

.testimonial {
  background-image: url("343.png") !important;
  overflow: hidden;
  background-position: right;
}

.testimonial-heading {
  margin-top: 66px !important;
}

.classic-1 {
  background-size: cover;
  bottom: 0;
  position: absolute;
  width: 100vw;
  background-image: url("group\ 775.png");
  height: 16.6875rem;
  background-position: center;
  z-index: 0;
}

.about-heading {
  position: relative;
  z-index: 98;
}

.avatars-bg {
  background-image: url("avatarsframe.png") !important;
  height: 100vh;
  max-height: 770px;
  background-size: contain;
  background-repeat: no-repeat;
}

.glows-bg {
  background-image: url("Gloww.png") !important;
  height: 100vh;
  max-height: 850px;
  background-size: cover;
  background-repeat: no-repeat;
}

.classic-2 {
  bottom: 0;
  position: absolute;
  right: 0;
}

.classic-3 {
  position: absolute;
  right: 0;
  top: 16vh;
}

.about-heading {
  color: #fff;
  font-family: Inter;
  font-size: 4.525rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5.25rem;
}

.about-heading-grad {
  background: linear-gradient(94deg, #e5f4ff 0%, #c253fe 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Glossy {
  border-radius: 1.875rem;
  background-color: #201e24;
  width: 100%;
  background-repeat: repeat;
  /* padding-block: 8.2vh; */
  box-sizing: border-box;
}

.GlossyParent {
  padding-inline: 8.78vw;
  box-sizing: border-box;
}

.ideas-plate-parent {
  height: 29em;
  box-sizing: border-box;
  padding: 2.3125em 1em 2.3125em 4.1875em;
  font-size: 16px;
  grid-template-columns: 1.3fr 1fr;
  display: grid;
  gap: 1.625em;
  align-items: center;
}

.ideas-plate {
  height: 100%;
  box-sizing: border-box;
  display: inline-flex;
  padding: 3.8125em 1.625em 3.75em 1.625em;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.8125em;
  border: 0.0625em solid #b6b6b6;

  background: linear-gradient(121deg,
      rgba(124, 105, 150, 0.67) 5.7%,
      rgba(153, 93, 228, 0.54) 92.96%);
  color: #fff;

  font-family: Inter;

  font-style: normal;
  font-weight: 700;
}

.ideas-text {
  font-size: 34px;
  line-height: 54px;
  text-align: left;
}

.picture {
  max-width: 20.9375em;
  max-height: 20.9375em;
  border-radius: 50%;
  filter: grayscale(1);
}

.info-ideas {
  display: inline-flex;
  height: 4.9775em;
  padding: 1.0729em 2.7929em 1.0921em 7.597em;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.25em;
  background: rgba(255, 255, 255, 0.13);
  position: absolute;
  right: 0;
  flex-direction: column;
  justify-content: center;
}

.picture-parent {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1420px) {
  .info-ideas {
    display: inline-flex;
    height: 4.9775em;
    padding: 0.8em 4.7929em 0.8em 4.7929em;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1.25em;
    background: rgba(35, 33, 33, 0.51);
    position: absolute;
    right: unset;
    flex-direction: column;
    justify-content: center;
    bottom: -3em;
  }

  .picture-parent {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 1073px) {
  .picture {
    max-width: 8em;
    max-height: 8em;
    border-radius: 50%;
    filter: grayscale(1);
  }

  .text-services {
    color: #fff;
    font-family: Lexend Deca;
    font-size: 35px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px !important;
    text-align: center !important;
    margin-block: 2rem !important ;
    padding: unset !important;
}

.text-services::after {
  display: none !important;
}

  .ideas-plate-parent {
    height: max-content;
    box-sizing: border-box;
    padding: 2.3125em 4.1875em 2.3125em 4.1875em;
    font-size: 16px;
    grid-template-columns: 1fr;
    display: grid;
    gap: 1.625em;
    align-items: center;
  }

  .picture-parent {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
  }

  .picture {
    max-width: 6em;
    max-height: 6em;
    border-radius: 50%;
    filter: grayscale(1);
  }

  .info-ideas {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1.25em;
    background: none;
    right: unset;
    flex-direction: column;
    justify-content: center;
    bottom: 0em;
    padding: 0;
    position: static;
  }

  .ideas-text {
    font-size: 26px;
    line-height: 40px;
    text-align: left;
  }
}

.hover-gray {
  filter: grayscale(1);
  transition-property: filter;
  transition-duration: 2s;
}

.hover-gray:hover {
  filter: none;
}

.swiper {
  height: auto !important;
}

.career-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
}

.members {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 10.7vh;
}

.palette {
  display: grid;
  grid-template-columns: repeat(3, 22rem);
  grid-template-rows: 16rem;
  gap: 5rem;
  padding-block: 7.6vh;
}

.gray-tinge {
  background: lightgray 50% / cover no-repeat, #c4c4c4;
}

.member-info {
  position: absolute;
  bottom: -4.875rem;
  z-index: 0;
  width: 22rem;
  padding: 2.5rem 0rem 1.375rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: rgba(255, 255, 255, 0.13);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.member-name {
  color: #fff;
  font-family: Rubik;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5625rem;
}

.member-description {
  color: #adadad;
  font-family: Rubik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.testimonial-typo {
  color: #989898;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 27px */
}

.testimonial-color-white {
  color: #fff;
}

.stats-container {
  width: 100%;
  display: grid;
  font-size: 16px;
  grid-template-columns: repeat(4, 1fr);
  /* padding-block: 9vh; */
}

.stats-sub-container {
  display: flex;
  flex-direction: column;
  gap: 1.125em;
}

.stats-item-a {
  color: var(--var, #aa67fe);
  font-family: Inter;
  font-size: 6em;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
}

.stats-item-b {
  color: #fff;
  font-family: Inter;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .content {
    font-size: 13px !important;
  }

  html {
    font-size: 12px;
  }

  .second-grid>div {
    padding-inline: 0 !important;
  }

  .cards {
    width: 100% !important;
  }

  .thumbGrid>div>a>.title-white {
    font-size: 1.5rem !important;
    line-height: 24px !important;
    margin-top: 15px;
    padding-inline: 0.8rem;
  }

  .thumbGrid>div>a>.subtitle {
    font-size: 1rem;
    line-height: 16px !important;
    padding-inline: 0.8rem;
    margin-top: 5px;
  }

  .home-third {
    background: none !important;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 12px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .snap-scroll {
    position: relative;
    z-index: 10000000;
    top: -60px !important;
  }
}

@media screen and (max-width: 639px) {
  .fading-plates>div {
    font-size: 15px !important;
    position: absolute !important;
    height: 370px !important;
    width: 100% !important;
    overflow: hidden !important;
    /* gap: 14px !important; */
    border-radius: 0px !important;
    flex-direction: column !important;
  }

  .scroll-horizontal-section>div:last-child {
    height: 75% !important;
  }

  .avatars-bg {
    background-size: cover;
    background-position: 47% -3rem;
  }

  .second-grid {
    padding-top: 5rem !important;
  }

  .content {
    font-size: 13px;
  }

  .subHeading {
    font-size: 8px !important;
  }

  .second-grid {
    grid-template-columns: 1fr !important;
    justify-items: center !important;
  }

  .third-flex {
    flex-direction: column;
  }

  .third-flex-text {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .home-third {
    background-position: left;
    padding-block: 59px;
  }

  .txt-left {
    text-align: center;
  }

  .third-flex {
    gap: 60px;
  }

  .features {
    grid-template-columns: unset;
    grid-template-rows: unset;
    width: 85%;
  }

  .features>div {
    font-size: 1.1rem;
    justify-content: start;
    padding: 1rem 0.7281rem;
  }

  .home-third {
    background: none;
  }

  .cards>div:nth-child(10) {
    background: #e7faf3;
    grid-row: initial;
    grid-column: initial;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: initial;
    grid-row-gap: 2.4375rem;
    width: 85%;
  }

  .cards>div {
    padding-block: 1.5rem;
  }

  .cards>div>div {
    max-width: unset !important;
  }

  .home-fifth {
    text-align: center;
    padding-block: 1rem;
  }

  .home-sixth {
    text-align: center;
  }

  .thumbGrid {
    grid-template-columns: 250px !important;
  }

  section {
    box-sizing: border-box;
    padding-inline: 30px !important;
  }

  .home-second {
    padding-inline: 0px !important;
  }



  .home-second>.gapper>div {
    padding-inline: 30px !important;
  }

  .second-grid>div {
    padding-inline: unset !important;
  }

  .palette {
    gap: 5rem !important;
    grid-template-columns: 13rem;
    grid-template-rows: repeat(3, 13rem) !important;
  }

  .member-info {
    align-items: center;
    background: #3e3d3db8;
    border-radius: 1.25rem;
    bottom: -2.875rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 1.375rem;
    position: absolute;
    width: 17rem;
    z-index: 2;
  }

  .scroll-horizontal-section {
    flex-direction: column !important;
    gap: 4rem !important;
    height: 90vh !important;
    /* justify-content: center !important; */
    padding-block: 0vh !important;
  }

  .scroll-horizontal-section>div:last-child {
    height: 40vh !important;
    width: 80vw !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  .fading-plates>img {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .fading-plates>div {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .about-hero-section {
    /* background-size: contain; */
    background-size: 100vh;
    background-position: center;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .article-container {
    /* grid-template-rows: 1fr 2fr !important; */
  }
}

@media screen and (max-width: 500px) {
  .fading-plates>div {
    font-size: 12px !important;
    position: absolute !important;
    height: 370px !important;
    width: 100% !important;
    overflow: hidden !important;
    /* gap: 14px !important; */
    border-radius: 0px !important;
    flex-direction: column !important;
  }

  .ideas-text {
    font-size: 15px;
    line-height: 30px;
    text-align: left;
  }

  .ideas-plate-parent {
    padding: 3em 2em;
  }

  .ideas-plate {
    padding: 2em 1.625em 2em 1.625em;
  }

  .picture-parent {
    scale: 0.8;
  }

}

/* regular css begins */

.caro-plate-1 {
  background: url("112.png");
  background-position: center 10%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.caro-plate-1-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.caro-info-container {
  display: flex;
  gap: 0.625em;
  align-items: center;
  padding: 2.5em;
}

.caro-avatar {
  width: 3.5625em;
  height: 3.5625em;
  object-fit: cover;
  border-radius: 0.625em;
}

.caro-plate-2 {
  border-radius: 1.8125em;
  border: 1px solid #b6b6b6;
  padding: 2.1875em;
  background: var(--grad1,
      linear-gradient(121deg,
        rgba(124, 105, 150, 0.67) 5.7%,
        rgba(153, 93, 228, 0.54) 92.96%));

  font-family: Inter;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: left;
  height: max-content;
}

.caro-color-main {
  color: #989898;
}

.caro-color-special {
  color: #fff;
}

.caro-plates {
  display: grid;
  grid-template-columns: repeat(2, minmax(18.75em, 25.5em));
  grid-template-rows: minmax(19.5625em, 1fr);
}

.caro-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-block: 116px 40px;
  width: max-content;
  padding-inline: 0px 70px;
  box-sizing: border-box;
  gap: 70px;
  font-size: 16px;
}

.Glossy-inner {
  padding-block: 6vh;
}

/* regular css ends */

@media screen and (min-width: 640px) and (max-width: 825px) {
  html {
    font-size: 12px;
  }

  .second-grid {
    grid-template-columns: 1fr !important;
    justify-items: center !important;
  }

  .cards {
    grid-template-columns: repeat(2, minmax(10px, 294px));
    grid-template-rows: repeat(5, 5.4375rem);
  }

  .third-flex-text {
    justify-content: center;
    text-align: center;
  }

  .third-flex {
    flex-direction: column;
    gap: 40px;
    padding-block: 3rem;
  }

  .features {
    grid-template-columns: repeat(2, 21.125rem);
    grid-template-rows: repeat(3, 3.943rem);
  }

  .features>div {
    font-size: 1.2rem;
  }

  .thumbGrid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .palette {
    gap: 5rem !important;
    grid-template-columns: 13rem;
    grid-template-rows: repeat(3, 13rem) !important;
  }

  .member-info {
    align-items: center;
    background: #3e3d3db8;
    border-radius: 1.25rem;
    bottom: -2.875rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 1.375rem;
    position: absolute;
    width: 17rem;
    z-index: 2;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 16px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .scroll-horizontal-section {
    flex-direction: column !important;
    gap: 4rem !important;
    height: 90vh !important;
    padding-block: 0vh !important;
  }

  .scroll-horizontal-section>div:last-child {
    height: 40vh !important;
    width: 80vw !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  .fading-plates>img {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .fading-plates>div {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .about-hero-section {
    /* background-size: contain; */
    background-size: 100vh;
    background-position: center;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .article-container {
    /* grid-template-rows: 1fr 2fr !important; */
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 14px;
  }

  .cards {
    grid-template-columns: repeat(2, minmax(10px, 294px));
    grid-template-rows: repeat(5, 5.4375rem);
  }

  .third-flex-text {
    justify-content: center;
    text-align: center;
  }

  .third-flex {
    flex-direction: column;
    gap: 40px;
    padding-block: 7rem;
  }

  .features {
    grid-template-columns: repeat(2, 21.125rem);
    grid-template-rows: repeat(3, 3.943rem);
  }

  .features>div {
    font-size: 1.2rem;
  }

  .palette {
    grid-template-columns: repeat(3, 13rem) !important;
    grid-template-rows: 12rem !important;
    gap: 5rem !important;
  }

  .member-info {
    width: 16rem;
  }
}

/* common changes upto 1023px */
@media screen and (max-width: 1023px) {
  .navbar {
    display: none;
  }

  .nav-toggle-button {
    display: block !important;
  }

  .mobile-navbar {

    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }

  .testimonial-d {
    columns: 1 !important;
  }

  .testimonial-f {
    display: block !important;
  }

  .testimonial-c {
    display: none;
  }

  .thumb-parent.story {
    padding-top: 6.1rem !important;
  }

  .center {
    font-size: 21px;
    /* ipad air */
  }

  .caro-container {
    padding-block: 66px 66px;
  }

  .caro-parent {
    /* min-height: 100vh !important; */
    height: max-content !important;
    padding-inline: 3em;
  }

  .caro-plates {
    display: flex;
    flex-direction: column-reverse;
    max-width: 300px;
    justify-content: flex-end;
  }

  .caro-container {
    font-size: 14px;
  }

  .read-container {
    padding-inline: 2rem !important;
    /* text-align: justify !important; */
  }

  .tools {
    padding-inline: 10vw;
    /* padding-block: 1.25rem 2.3125rem; */
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 20;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .search {
    width: 95% !important;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 9.2vh;
  }

  .thumbGrid {
    gap: 24px 24px !important;
  }

  .home-third {
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.35906862745098034) 16%,
        rgba(0, 0, 0, 1) 100%),
      url("81.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
  }

  .second-grid>img {
    width: 100% !important;
    grid-row: 1;
    margin-bottom: 2rem;
  }

  .members {
    text-align: center;
  }

  .Glossy-inner {
    align-items: start !important;
    gap: 1rem !important;
    flex-direction: column !important;

  }

  .article-container {
    height: unset;
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr; */
    padding: 3rem 1rem;
    box-sizing: border-box;
    background: rgb(46, 46, 46);
    place-items: center;
  }

  .blackie {
    height: unset;
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr;
    padding: 3rem 1rem;
    box-sizing: border-box;
    background: transparent;
    place-items: center;
  }

  .testimonial-typo {
    color: #989898;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  .article-subcontainer-sub {
    text-align: center;
    gap: 40px;
  }

  .outlined-btn {
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 1.3rem;
    padding: 0.2625rem 1.2813rem 0.2625rem 1.2188rem;
  }

  .article-image {
    margin-block: 2rem;
    grid-row: 1;
    width: 100%;
    max-width: 388px;
  }

  .tools>div {
    width: 100%;
  }

  .scroll-horizontal-section {
    flex-direction: column !important;
    gap: 2rem !important;
    height: 100vh !important;
    justify-content: top !important;
    padding-block: 7rem !important;
  }

  .scroll-horizontal-section>div:last-child {
    height: 60% !important;
    width: 80vw !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 200px 1fr !important;
  }

  .fading-plates>img {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }



  .fading-plates>div {
    font-size: 21px;
    position: absolute !important;
    height: 432px !important;
    width: 100% !important;
    overflow: hidden;
    text-align: center;
    gap: 14px;
    border-radius: 0px;
    margin-top: 10rem;
    flex-direction: column;
  }

  .snap-scroll {
    position: relative;
    z-index: 10000000;
    /* top: 19.5vh; */
  }

  .about-hero-section {
    background-size: cover;
    background-repeat: repeat;
    background-position: left;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .article-container {
    /* grid-template-rows: 1fr 2fr !important; */
  }
}

@media screen and (max-height: 400px) {
  .testimonial {
    background-image: url(http://localhost:3000/static/media/343.e01e73c….png) !important;
    overflow: hidden;
    padding-top: 4rem !important;
  }

  .fading-plates {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {


  .Maal {
    grid-template-columns: 1fr !important;
  }

  .fading-plates>div {
    position: absolute;
    width: 53.33vw;
    height: unset;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    color: #898CA9;
    padding: 3.125rem 4.375rem;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    gap: 3.375rem;
    opacity: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .third-flex {
    justify-content: center;
  }

  .palette {
    grid-template-columns: repeat(3, 13rem) !important;
    grid-template-rows: 12rem !important;
    gap: 5rem !important;
  }

  .member-info {
    width: 16rem;
  }

  .Glossy-inner {
    align-items: start !important;
    gap: 1rem !important;
    flex-direction: column !important;
  }

  .thumbGrid {
    gap: 25px 40px !important;
  }

  .stats-container {
    font-size: 14px;
  }

  .article-image {
    width: 80%;
  }

  .testimonial-typo {
    color: #989898;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .caro-container {
    font-size: 12px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1535px) {
  .caro-container {
    font-size: 13px;
  }

  .thumbGrid {
    gap: 25px 40px !important;
  }

  .article-image {
    width: 80%;
  }
}

@media screen and (min-width: 1536px) {}

@media screen and (max-height: 700px) and (max-width: 500px) {
  .third-flex {
    padding-block: unset !important;
  }
}

@media screen and (min-height: 900px) and (max-width: 900px) {
  .third-flex {
    padding-block: 9vh !important;
  }

}

@media screen and (min-height: 920px) and (max-width: 900px) {

  /* .home-third {
    min-height: 67vh;
  } */
  .home-fifth {
    padding-block: 66px 0px;
  }
}

@media screen and (min-height: 1024px) and (max-width: 900px) {
  .center {
    font-size: 20px;
  }

  .scroll-horizontal-section {
    flex-direction: column !important;
    gap: 4rem !important;
    height: 90vh !important;
    justify-content: center !important;
    padding-block: 0vh !important;
  }

  .scroll-horizontal-section>div:last-child {
    height: 60vh !important;
    width: 80vw !important;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }

  .snap-scroll {
    scale: 1.3;
  }

  .fading-plates>img {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .fading-plates>div {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
  }

  .about-hero-section {
    /* background-size: contain; */
    background-size: cover;
    background-position: center;
  }

  .stats-container {
    width: 100%;
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 9.2vh !important;
    justify-content: center !important;
  }

  .article-container {
    /* grid-template-rows: 1fr 2fr !important; */
  }
}

@media screen and (min-height: 1366px) and (max-width: 900px) {
  .center {
    font-size: 24px;
  }
}

.marquee-child {
  padding-block: 3.125rem 6.375rem;
  /* keep width same as supplied to the <Marquee> */
  width: max-content;
  display: flex;
}

.marquee-child>svg {
  padding-inline: 2rem;
}

.swiper {
  width: 100%;
  height: 75vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.tools.story {
  position: absolute;
}

.thumb-parent.story {

  padding-block: 4.1rem;
}

.testimonial-a {
  padding-inline: unset !important;
  border-radius: 0px !important;
  padding-bottom: 0px !important;
  cursor: default;
}

.testimonial-b {
  display: none !important;
}

.testimonial-c {
  position: absolute;
  z-index: 1;
  right: 5rem;
  top: 8rem;
  cursor: default;
  text-align: center;
}

.testimonial-d {
  /* background: url("lines.png"); */
  background-size: contain;
  columns: 2;
  column-gap: 20px;
  padding-block: 3.75rem !important;
}

.testimonial-e {
  padding-block: 0 !important;
}

.testimonial-f {
  display: none;
}

.contact {
  background-image: url("contact.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* services form */
.date-picker-text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  /* 225% */
}

.grid-container {
  display: grid;
  grid-template-rows: repeat(7, auto);
  gap: 53px;
  padding-block: 147px 53px;
  padding-inline: 27px;
}

.grid-container>div:nth-child(1),
.grid-container>div:nth-child(3) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 53px;
}

.grid-item {
  display: flex;
  color: #fff;
  height: max-content;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  flex-direction: column;
  gap: 28px;
}

.grid-item>input {
  border-radius: 10px;
  padding: 18px 28px;
  background: rgba(255, 255, 255, 0.11);
  border: none;
  outline: none;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06),
    0px 20px 26px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  flex-grow: 1;
  color: #fff;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.textarea {
  border-radius: 10px;
  border: 2px solid #8b8b8b;

  background: rgba(255, 255, 255, 0.11);

  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06),
    0px 20px 26px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  width: 100%;
  max-height: 386px;
  height: 100%;
  padding: 28px;
  box-sizing: border-box;
}

.submit-btn {
  border-radius: 11px;
  border: 2px solid #9747ff;
  display: flex;
  padding: 9px 0px 8px 0px;
  color: var(--var, #aa67fe);

  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  width: 168px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.description-area {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  resize: none;
  text-align: left;
}

.radio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.radio-option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.radio-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #b3b3b3;
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06),
    0px 20px 26px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.radio-label {
  margin-left: 27px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.radio-input:checked+.radio-circle {
  background: #007bff;
  border-color: #007bff;
}

.radio-input:checked+.radio-circle::after {
  content: "✔";
  color: #fff;
  font-size: 16px;
}

.project {
  display: flex;
  gap: 17px;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox {
  position: relative;
  min-width: 26px;
  min-height: 26px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06),
    0px 20px 26px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  cursor: pointer;
}

.checkbox-input {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  margin-left: 27px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  width: max-content;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.checkbox:hover {
  background: rgba(0, 0, 0, 0.1);
}

.checkbox-input:checked+.checkbox {
  background: #007bff;
  border-color: #007bff;
}

.checkbox-input:checked+.checkbox::after {
  content: "✔";
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-container-parent {
  width: min-content;
  margin-left: auto;
  margin-right: auto;
}

.small-size-date-picker {
  display: none;
}

.gcp-child {
  /* column-gap: 11vw; */
}

/* 
use media uery for after 830px */
@media screen and (max-width: 830px) {

  .grid-container>div:nth-child(1),
  .grid-container>div:nth-child(3),
  .grid-container>div:nth-child(4) {
    grid-template-columns: unset !important;
  }

  .service-heading {
    padding-block: 0rem 5rem !important;
  }

  .grid-item>input {
    font-size: 18px;
  }

}

@media screen and (max-width: 1400px) {
  .gcp-child {
    column-gap: 5vw;
  }
}

@media screen and (max-width: 1250px) {
  .gcp-child {
    column-gap: 2vw !important;
  }
}

@media screen and (max-width: 1170px) {
  .grid-container>div:nth-child(4) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 53px;
  }

  .small-size-date-picker {
    display: block;
  }

  .large-size-date-picker {
    display: none;
  }

  .project {
    flex-direction: column;
  }

  .grid-container {
    padding-block: 53px 53px;
  }

  .gcp-child {
    grid-template-columns: 1fr !important;
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .grid-container-parent {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }

  .childofgcp {
    margin-inline: 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .grid-item {
    max-width: 290px !important;
  }

  .grid-container {
    justify-content: center;
    gap: 30px;
  }

  .grid-container>div {
    gap: 30px !important;
  }

  .childofgcp {
    /* width: 90%; */
  }

  .gcp-child {
    justify-items: center;
    width: unset !important;
  }

  .grid-container>.grid-item:last-child {
    display: flex;
    align-items: center;
  }

  .service-heading {
    padding-inline: 1rem;
  }

  .contact-grid>div {
    flex-grow: 1;
    width: 100%;
    display: flex;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    gap: 1rem;
  }
}

.grid-item>input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-info {
  border-radius: 30px;
  background: rgba(170, 103, 254, 0.11);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.06),
    0px 20px 26px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  max-width: 1273px;
  width: 100%;
  height: auto;
  position: absolute;
  /* bottom: -5rem; */
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1273px) {
  .contact-info {
    max-width: unset;
    width: unset !important;
    height: auto;
    left: unset;
    bottom: 4rem;
    transform: none !important;
    margin-inline: 2rem;
    position: relative;
  }
}

.contact-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 224px;
  display: grid;
  justify-items: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);

  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
}

.contact-grid>div:nth-child(2) {
  border-right: 2px solid rgba(85, 84, 84, 0.52);

  border-left: 2px solid rgba(85, 84, 84, 0.52);
}

.contact-grid>div>div {
  max-width: 342px;
}

.contact-grid>div {
  flex-grow: 1;
  width: 100%;
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 1rem;
}

.contact-grid>div>img {
  width: 66px;
  height: 66px;
  scale: 1.2;
  filter: drop-shadow(0px 4px 44.4px #aa67fe);
}

@media screen and (max-width: 1080px) {
  .contact-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 2rem !important;
    padding: 2rem !important;
  }

  .contact-info {
    margin-left: auto;
    margin-right: auto;
    width: 90% !important;
    bottom: 10rem;
  }

  .contact-grid>div:nth-child(2) {
    border-right: none;

    border-left: none;
  }

  .contact-grid>div {
    flex-grow: 1;
    width: 100%;
    display: flex;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none !important;
}

.subHeading>div>div {
  position: absolute;
}


.bubble-span {
  display: inline-block;
  overflow: hidden;
  opacity: 0;
  animation: .5s fade cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}


@keyframes fade {
  0% {
    opacity: 0;
    scale: 1.4;
    filter: blur(1px);
  }

  100% {
    opacity: 1;
    scale: 1;
    filter: blur(0px);
  }
}

@keyframes fade-inner {
  0% {
    font-size: 1.25rem;
  }

  50% {
    font-size: 1.5rem;
  }

  100% {
    font-size: 1.25rem;
  }
}

.services-section {
  background-repeat: no-repeat;
  /* height: 100vh; */
  background-attachment: fixed;
  background-size: 100vw;
  background-position: center;
  padding-block: 5rem;
  background-image: url(./services.png);
  background-color: #0a0909;
}

/* 
.services-section>div {
  position: sticky;
  top: 5rem;
  color: white;
}

.services-section>div>.title-white {
  text-align: center;
}

.curve {
  height: 604px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: end;
}

.service-number {
  color: #9747FF;
  font-family: "Lexend Deca";
  font-size: 6.875em;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  8.25rem 
  letter-spacing: -0.25em;
  text-transform: uppercase;
}

.service-name {
  font-family: "Lexend Deca";
  font-size: 1.25em;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  
}

.service-description {
  font-family: "Lexend Deca";
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  color: #C8CFD5;
  
}

.curve>div {
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 40px;
  width: 18.75em;
}

.curve>div:nth-child(2) {
  position: relative;
  top: -25%;
}

.curve>div:nth-child(3) {
  position: relative;
  top: -55%;
}

.curve-img {
  position: absolute;
  height: 28rem;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
} */

.text-services {
  font-family: "Lexend Deca";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 62.4px;
  color: white;
  padding: 65px 198px 0px 198px;
}

.text-services::after {
  display: block;
  width: 170px;
  height: 1px;
  content: " ";
  margin-top: 25px;
  background-color: #C8CFD5;
}

a {
  text-decoration: none !important;
  color: unset;
}

.home-second::before {
  display: block;
  position: absolute;
  top: -26px;
  width: 100%;
  height: 100px;
  /* background-color: red; */
  content: " ";
}

.social {
  display: flex;
  gap: 20px;
  padding-block: 20px;
  font-size: 23px;
}

.footerItemList {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
}

.footerItem {
  text-align: unset;
}

.social>a>div {
  background-color: #AA67FE;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MuiSlider-root {
  color: white !important;
}

.dropdown {
  display: inline-flex;
  padding: 59px 58px 50px 64px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 132px;
  border-radius: 0px 0px 25px 25px;
  background: #0c0c0cee;
  position: fixed;
  right: 7px;
  top: 65px;
  z-index: 101;
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
}

.dd-item {
  display: flex;
  gap: 15.75px;
  flex-direction: column;
}

.dd-item>div:first-child {
  color: #AA67FE;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 36px */
}

.dropdown {
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.dropdown.open {
  opacity: 1;
  visibility: visible;
  max-height: 200px;
  /* Set maximum height as per your requirement */
}

.spin-arrow {
  transition: all 0.3s ease-out;
}

.spin-arrow.open {
  transform: rotate(180Deg);
}

.light {
  background: rgba(255, 255, 255, 0.90);
  transition: all;
  transition-duration: 300ms;
}

.light>* {
  transition: all;
  transition-duration: 300ms;
}

.light .title-white {
  color: #000 !important;
}

.light .subtitle {
  color: rgba(0, 0, 0, 0.80);
}

.light .Glossy-inner .subtitle {
  color: #fff;
}

.light .button-tools {
  color: #000;
  border-color: #000;
}

.light .search {
  background: rgba(0, 0, 0, 0.13);
}

.light .blog-info>div>div:first-child {
  color: #000 !important;
}

.light .blog-info>div>div:last-child {
  color: rgba(0, 0, 0, 0.60);
}

.light .button-tools svg>path {
  fill: #666666;
}

.light .button-tools svg>g>path {
  fill: #666666;
}

.light .icon-btn svg>path {
  fill: black;
}

.light .content-blog {
  color: #000;
}

.light .content-blog-heading {
  color: #000;
}

.light .outlined-btn>svg>path {
  fill: #aa67fe;
  transition-property: fill;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.light .outlined-btn:hover>svg>path {
  fill: #fff;
}

.mode-switch {
  position: absolute;
  top: 100px;
  right: 40px;
  z-index: 99;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

.light .mode-switch {
  color: black;
}

.Footer {
  min-height: 394px;
  background: #1B1D21;
  padding: 90px 140px 40px 140px;
  text-align: center;
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
  background-size: cover;
  background-position: center;
}

.footerDesc {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

.footerItemList {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.footerHead {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  padding-bottom: 16px;
  border-bottom: solid 1px #AA67FE;
  width: max-content;
}

.footerItem {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  padding: 25px 0px;
  cursor: pointer;
}

.footerItem.contactItem div {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
  cursor: default;
}

.footerItem.contactItem div::before {
  font-size: 22px;
}

.footerItem div::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  padding-right: 10px;
  color: #AA67FE;
}

.footerItem.contactItem div:nth-child(1)::before {
  content: "\f3c5" !important;
}

.footerItem.contactItem div:nth-child(2)::before {
  content: "\f0e0" !important;
}

.footerItem.contactItem div:nth-child(3)::before {
  content: "\f2a0" !important;
}

.footerItem.contactItem {
  gap: 30px;
}

.Maal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 65%;
}

.MaalOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}


.logofooter {
  width: 190px;
  height: 190px;
}

.Footer h1 {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

.footercloser {
  padding-top: 3rem;
  text-align: center;
  display: flex;
}

.copyright {
  flex-grow: 1;
}

.closerlist {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  font-family: "Lexend Deca";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}



/* locomotive scroll */

/* html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
} */


.service {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #727272;
  background-color: rgba(21, 21, 21, 0.5);
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 3rem;
  z-index: 50;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin:1rem;
}

.main-box {
  display: flex;
  width: 100%;
  gap: 2rem;
  font-family: 'Inter', sans-serif;
  flex-wrap: wrap;
  justify-content: center;
}
.service:hover {
  transform: translateY(-5px);
}

.circle {
  width: 7rem;
  height: 7rem;
  left: 0px;
  top: 0px;
  background: rgba(151, 71, 255, 0.39);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:2rem; */
}

.servicelogo{
    width:100%;
    height:100%;

}

.title {
  text-align: center;
  text-transform: uppercase;
  color: #8b5cf6;
  font-size: 2.25rem;
  font-weight: 600;
}

.development {
  color: #ffffff;
}

.content2 {
  text-align: center;
  color: white;
  line-height: 18px;
}

.Ellipse1 {
  width: 51px;
  height: 49px;
  background: linear-gradient(202deg, rgba(175.63, 126.59, 237.36, 0.56) 0%, rgba(40.16, 40.16, 40.16, 0) 100%);
  box-shadow: 0px 98px 250px 313px #453A52;
  border-radius: 9999px;
  display: none;
  position: absolute;
  right: -150px;
  top: -200px;
  z-index: -4;
  animation: ellipseAnimation 1s ease forwards; /* Added animation */
}

@keyframes ellipseAnimation {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
}

.service:hover .Ellipse1 {
  display: block;
}
